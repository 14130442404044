export const readCookie = (name) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) == 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
};

export const prepareImageURL = (
  picture,
  website,
  width = null,
  height = null,
  display_watermark = false
) => {
  if (!picture) {
    return null;
  }
  
  let params = new URLSearchParams;
  if (height || width) {
    if (height) {
      params.set('h', height);  
    }
    if (width) {
      params.set('w', width);  
    }
	  params.set('fit', 'crop');  
  }
  if (display_watermark && website) {
	  params.set('website', website); 
  }
  
  return (display_watermark ? picture.watermark_url : picture.image_url) + '?' + params.toString();
};

export const formatLink = (link) => {
  if (!link.startsWith("https://") && !link.startsWith("http://")) {
    link = link[0] == "/" ? link : "/" + link;
  }

  return link;
};

export const compareObjects = (o1, o2) => {
  for (var i in o1) {
    if (o2.hasOwnProperty(i)) {
      if (o1[i] != o2[i]) {
        return false
      }
    } else {
      return false;
    }
  }

  for (var i in o2) {
    if (o1.hasOwnProperty(i)) {
      if (o1[i] != o2[i]) {
        return false
      }
    } else {
      return false;
    }
  }

  return true;
};

export const numberFormat = (number, decimals, decPoint, thousandsSep) => { // eslint-disable-line camelcase
  //  discuss at: https://locutus.io/php/number_format/
  number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
  const n = !isFinite(+number) ? 0 : +number
  const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
  const sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
  const dec = (typeof decPoint === 'undefined') ? '.' : decPoint
  let s = ''

  const toFixedFix = function (n, prec) {
    if (('' + n).indexOf('e') === -1) {
      return +(Math.round(n + 'e+' + prec) + 'e-' + prec)
    } else {
      const arr = ('' + n).split('e')
      let sig = ''
      if (+arr[1] + prec > 0) {
        sig = '+'
      }
      return (+(Math.round(+arr[0] + 'e' + sig + (+arr[1] + prec)) + 'e-' + prec)).toFixed(prec)
    }
  }

  // @todo: for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec).toString() : '' + Math.round(n)).split('.')
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || ''
    s[1] += new Array(prec - s[1].length + 1).join('0')
  }

  return s.join(dec)
};

export const prepareFlagURL = (locale = 'en') => {
	return `https://app.realestatecrm.io/images/vendor/flag-icon-css/flags/4x3/${locale == "en" ? "us" : locale}.svg`;
}; 
