import PropTypes from "prop-types";
import React, { useContext } from "react";
import { Helmet } from "react-helmet";

import { templateVariableValue } from "../../../helpers/templateVariables";
import SiteMetaContext from "../../../contexts/SiteMetaContext";

function SEO({ meta, title, metaTitle }) {
  const siteData = useContext(SiteMetaContext);
  const {
    site,
    remarso: {
      domainByURL: {
        website: {
          name,
          templateConfiguration,
          template: { templateVariables },
          company,
        },
      },
    },
    currentLocaleKey: locale,
    defaultLocaleKey: defaultLocale,
  } = siteData;

  let metaDescription =
    templateVariableValue(
      templateConfiguration,
      templateVariables,
      "meta-description",
      locale,
      defaultLocale
    ) || site.siteMetadata.description;

  const siteMetaTitle = metaTitle || site.siteMetadata.title;

  const keywords = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "meta-keywords",
    locale,
    defaultLocale
  );

  const siteTitle =
    title ||
    templateVariableValue(
      templateConfiguration,
      templateVariables,
      "title",
      locale,
      defaultLocale
    ) ||
    name;

  const favicon = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "logo-favicon",
    locale,
    defaultLocale
  );

  let link = [];
  if (favicon) {
    link.push({
      rel: `icon`,
      type: `image/png`,
      href: favicon,
    });
  }

  return (
    <Helmet
      htmlAttributes={{
        locale,
      }}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: siteMetaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: keywords,
        },
      ].concat(meta)}
      title={siteTitle}
      titleTemplate={`%s | ${siteTitle}`}
      link={link}
    >
      <script type="text/javascript">
        {company?.matomoTrackingScript?.replace(/<[^>]*>/g, "")}
      </script>
    </Helmet>
  );
}

SEO.defaultProps = {
  meta: [],
};

SEO.propTypes = {
  meta: PropTypes.array,
  title: PropTypes.string,
  metaTitle: PropTypes.string
};

export default SEO;