import PropTypes from "prop-types";
import React, { useContext } from "react";
import Header from "./header";
import Footer from "./footer";
import SiteMetaContext from "../../../contexts/SiteMetaContext";
import * as theme1Styles from "../scss/theme1.module.scss";

function Layout({ children }) {
  const siteData = useContext(SiteMetaContext);

  const {
    remarso: {
      domainByURL: {
        website: {
          theme: colorTheme = "theme-default",
          setting: display_settings,
        },
      },
    },
  } = siteData;

  const { display_footer } = display_settings;

  let themeColor = `${theme1Styles.themeDefault}`;
  if (colorTheme == "theme-blue") {
    themeColor = `${theme1Styles.themeBlue}`;
  } else if (colorTheme == "theme-white") {
    themeColor = `${theme1Styles.themeWhite}`;
  } else if (colorTheme == "theme-red") {
    themeColor = `${theme1Styles.themeRed}`;
  } else if (colorTheme == "theme-orange") {
    themeColor = `${theme1Styles.themeOrange}`;
  } else if (colorTheme == "theme-yellow") {
    themeColor = `${theme1Styles.themeYellow}`;
  } else if (colorTheme == "theme-green") {
    themeColor = `${theme1Styles.themeGreen}`;
  } else if (colorTheme == "theme-purple") {
    themeColor = `${theme1Styles.themePurple}`;
  }

  const wrapperClass = `flex flex-col min-h-screen font-sans text-gray-900 ${themeColor}`;

  return (
    <div className={wrapperClass}>
      <Header />
      <main className="">{children}</main>
      {(() => {
        if (display_footer) {
          return <Footer />;
        }
      })()}
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
