import React, { Component } from "react";
import _ from "lodash";

import ContactFormComponent1 from "../themes/theme1/components/ContactFormComponent1";
import ContactFormComponent2 from "../themes/theme2/components/ContactFormComponent2";
import ContactFormComponent3 from "../themes/theme3/components/ContactFormComponent3";
import ContactFormComponent4 from "../themes/theme4/components/ContactFormComponent4";
import ContactFormComponent5 from "../themes/theme5/components/ContactFormComponent5";

import SiteMetaContext from "../contexts/SiteMetaContext";
import trackip from "./trackip";

import "react-intl-tel-input/dist/main.css";

const validEmailRegex = RegExp(
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

class ContactFormContainer extends Component {
  static contextType = SiteMetaContext;

  constructor() {
    super();
    let formData = {};
    if (typeof window !== "undefined") {
      formData = JSON.parse(window.sessionStorage.getItem('formData') || '{}');
    }
    this.state = {
      weblisting_id: 0,
      api_success: "",
      api_error: "",
      api_key: "",
      owned_by: "",
      first_name: formData.first_name || "",
      last_name: formData.last_name || "",
      phone: formData.phone || "",
      phone_country: formData.phone_country || "",
      phone_code: formData.phone_code || "",
      email: formData.email || "",
      description: "",
      errors: {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
      },
      submitted: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateFormValues = this.validateFormValues.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.prepareFormData = this.prepareFormData.bind(this);
  }

  componentDidMount() {
    const {
      remarso: {
        domainByURL: {
          website: { id: weblisting_id },
        },
      },
    } = this.context;

    this.setState({ weblisting_id });

    this.setAssignedTo();

    this.setApiKey();
  }

  setAssignedTo() {
    const {
      remarso: {
        domainByURL: {
          website: {
            assigned_to_user,
            assigned_to_group,
            webform_assigned_to_user,
            webform_assigned_to_group,
            webform_assigned_type,
          },
        },
      },
      building,
      property,
    } = this.context;

    let owned_by_type = null;
    let owned_by_id = null;

    if ((building || property) && webform_assigned_type) {
      const listing = building || property;
      owned_by_type = listing.assigned_to_user ? 'user' : (listing.assigned_to_group ? 'group' : null);
      owned_by_id = listing.assigned_to_user?.id || listing.assigned_to_group?.id || null;
    }

    if (!owned_by_type || !owned_by_id) {
      owned_by_type = webform_assigned_to_user ? 'user' : (webform_assigned_to_group ? 'group' : null);
      owned_by_id = webform_assigned_to_user?.id || webform_assigned_to_group?.id || null;
    }

    if (!owned_by_type || !owned_by_id) {
      owned_by_type = assigned_to_user ? 'user' : (assigned_to_group ? 'group' : null);
      owned_by_id = assigned_to_user?.id || assigned_to_group?.id || null;
    }

    let owned_by = owned_by_type && owned_by_id ? owned_by_type + '-' + owned_by_id : "";

    this.setState({ owned_by });
  }

  setApiKey() {
    const {
      remarso: {
        domainByURL: {
          website: {
            company: {
              api_key
            },
            assigned_to_user,
            assigned_to_group,
          },
        },
      },
    } = this.context;

    if (api_key) {
      this.setState({ api_key });
    } else {
      let assigned_user = assigned_to_user || assigned_to_group?.leader || null;
      this.setState({
        api_key: assigned_user?.api_key || ""
      });
    }
  }

  handleChange(event) {
    event.preventDefault();

    const { name, value } = event.target;
    let errors = this.validateFormValues(name, value);

    this.setState({ errors, [name]: value });
  }

  handlePhoneChange(isValid, number, country) {
    let errors = this.state.errors;
    if (isValid) {
      errors.phone = "";
    } else {
      errors.phone = "Phone Number is not valid";
    }
    this.setState({ errors, phone: number, phone_country: country.iso2, phone_code: country.dialCode });
  }

  validateFormValues(name, value) {
    let errors = this.state.errors;
    switch (name) {
      case "first_name":
        errors.first_name = value.length <= 0 ? "First Name is required!" : "";
        break;
      case "last_name":
        errors.last_name = value.length <= 0 ? "Last Name is required!" : "";
        break;
      case "email":
        errors.email = validEmailRegex.test(value) ? "" : "Email is not valid!";
        break;
      case "phone":
        errors.phone = value.length <= 0 ? "Phone Number is required!" : "";
        break;
      default:
        break;
    }
    return errors;
  }

  serializeForm(form) {
    /**
     * The JSON placeholder doesn’t accept FormData.It wants a JSON object.
     **/
    let obj = {};
    let formData = new FormData(form);
    for (let key of formData.keys()) {
      obj[key] = formData.get(key);
    }
    return obj;
  }

  prepareFormData(form, api_key, owned_by) {
    const {
      remarso: {
        domainByURL: {
          website: {
            setting: {
              display_message_in_form
            }
          },
        },
      },
    } = this.context;

    let obj = {};
    let formData = new FormData(form);
    for (let key of formData.keys()) {
      obj[key] = formData.get(key);
    }
    obj["phone_code"] = this.state.phone_code;

    this.setSessionData(obj);

    obj["name"] = obj["first_name"] + ' ' + obj["last_name"];
    obj["api_key"] = api_key;
    obj["owned_by"] = owned_by;
    trackip().then((ipDetails) => {
      obj["addr_city"] = ipDetails.city;
      obj["addr_country_id"] = ipDetails.country;
    });

    const { location } = this.context;
    obj["lead_source_description"] = location.href;

    /**
     * Added only for the listing details page
     */
    const { building, property } = this.context;
    if (building) {
      obj["listing_type"] = "building";
      obj["listing_id"] = building.id;
      obj["name"] += ' - ' + building.name;
    } else if (property) {
      obj["listing_type"] = "property";
      obj["listing_id"] = property.id;
      obj["name"] += ' - ' + property.reference;
      if (property.selling_price || property.rental_price) {
        obj["annual_revenue"] = property.selling_price || property.rental_price;
      }
    }

    if (display_message_in_form && (building || property)) {
      obj["listing_notes"] = obj["description"];
      delete obj["description"];
    }

    obj["weblisting_id"] = this.state.weblisting_id;

    return obj;
  }

  setSessionData(obj) {
    if (typeof window !== "undefined") {
      window.sessionStorage.setItem('formData', JSON.stringify({
        ..._.omit(obj, ["description"]), 
        ...{ 
          phone_country: this.state.phone_country,
        }
      }));
    }
  }

  handleSubmit(event) {
    const {
      remarso: {
        domainByURL: {
          website: {
            company: {
              email: company_email
            }
          },
        },
      },
    } = this.context;

    event.preventDefault();
    const formValues = this.serializeForm(event.target);
    for (let key in formValues) {
      let errors = this.validateFormValues(key, formValues[key]);
      this.setState({ errors, [key]: formValues[key] });
    }

    if (validateForm(this.state.errors)) {
      const prepareFormData = this.prepareFormData(
        event.target,
        this.state.api_key,
        this.state.owned_by
      );
      this.setState({ api_success: "", api_error: "", submitted: true });
      const url = process.env.GATSBY_API_ENDPOINT + "weblisting/leads/create";
      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(prepareFormData),
      })
      .then(function (response) {
        if (response.ok) {
          return response.json();
        }
        return Promise.reject(response);
      })
      .then((data) => {
        if (data.status) {
          if (typeof window !== "undefined" && window._paq) {
            const userId = data.data.uuid;
            document.cookie = `userId=${userId}`;
            window._paq.push(["setUserId", userId]);
            window._paq.push([
              "trackEvent",
              "submit",
              "Lead submission",
              document.domain + "/" + document.title,
            ]);
          }
          this.setState({ api_error: "", submitted: false });
          if (data.message.code == 'listing_added') {
            this.setState({ api_success: data.message.message });
          } else {
            this.setState({ api_success: "Thanks for submitting your query." });
          }
        } else {
          this.setState({ api_success: "", submitted: false });
          this.setState({ api_error: data.message.message });
        }
      })
      .catch((error) => {
        if (error.json) {
          error.json().then((data) => {
            if (data.code == 'already_received_your_inquiry') {
              this.setState({ api_error: data.message });
            } else {
              this.setState({ api_error: `Connection error: please contact directly ${company_email}.` });
            }
          });
        } else {
          this.setState({ api_error: `Connection error: please contact directly ${company_email}.` });
        }
        this.setState({ api_success: "", submitted: false });
      });
    } else {
      console.error("Invalid Form");
    }
  }

  render() {
    const { currentLocaleKey, defaultLocaleKey } = this.context;
    const { template } = this.context.remarso.domainByURL.website;
    /** Begin: Theme Switcher */
    const templateId =
      process.env.GATSBY_TEST_THEME === "0"
        ? template.id
        : process.env.GATSBY_TEST_THEME;
    const themes = [
      ContactFormComponent1,
      ContactFormComponent2,
      ContactFormComponent3,
      ContactFormComponent4,
      ContactFormComponent5,
    ];
    const ContactFormComponent =
      parseInt(templateId) > 0
        ? themes[parseInt(templateId) - 1]
        : ContactFormComponent1;
    /** End: Theme Switcher */
    return (
      <ContactFormComponent
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        data={this.state}
        handlePhoneChange={this.handlePhoneChange}
        locale={currentLocaleKey}
        defaultLocale={defaultLocaleKey}
      />
    );
  }
}

export default ContactFormContainer;
