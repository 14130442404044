import React, { useState, useContext } from "react";
import { Link as ReactScrollLink } from "react-scroll";
import { Link } from "gatsby";

import SiteMetaContext from "../../../contexts/SiteMetaContext";
import { templateVariableValue } from "../../../helpers/templateVariables";
import {
  getTranslatedValue,
  getTranslatedLink,
  translateStatic,
} from "../../../helpers/translationHelper";
import { formatLink, prepareFlagURL } from "../../../helpers/helper";
import * as theme1Styles from "../scss/theme1.module.scss";

import default_logo from "../images/logo.png";
import dnarrow from "../images/down-arrow-black.svg";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);
  const [isShowLang, toggleLang] = useState(false);
  const [isSubMenu, toggleSubMenu] = useState(false);
  const [menuIndex, setMenuIndex] = useState(null);
  const siteData = useContext(SiteMetaContext);

  const handleSubMenu = (menu_id) => {
    if (menu_id == menuIndex) {
      toggleSubMenu(!isSubMenu);
    } else {
      toggleSubMenu(true);
      setMenuIndex(menu_id);
    }
  };

  const {
    remarso: {
      domainByURL: {
        website: {
          company: { logo: company_logo },
          logo,
          menus,
          setting: display_settings,
          templateConfiguration,
          template: { templateVariables },
        },
      },
    },
    location,
    locales,
    currentLocale,
    currentLocaleKey,
    defaultLocaleKey,
  } = siteData;

  const headerLogo = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "logo-header",
    currentLocaleKey,
    defaultLocaleKey
  );
  const showLogo = headerLogo || logo || company_logo || default_logo;

  const {
    display_company_logo,
    display_contact_form,
    display_menu,
  } = display_settings;

  return (
    <header className={`${[theme1Styles.mainHeader].join(" ")}`}>
      <div className={theme1Styles.wrapper}>
        <div className={`flex justify-between`}>
          {(() => {
            if (display_company_logo) {
              return (
                <Link to={getTranslatedLink("/", currentLocaleKey)}>
                  <h1 className={`flex items-center text-white no-underline`}>
                    <img
                      alt="logo"
                      className={theme1Styles.logo}
                      src={showLogo}
                    />
                  </h1>
                </Link>
              );
            }
          })()}
          <div className={theme1Styles.headerRight}>
            <nav
              className={`${isExpanded ? `${theme1Styles.showNow}` : ` `} ${
                theme1Styles.navC
              } md:block md:flex md:items-center w-full md:w-auto`}
            >
              {(() => {
                if (display_menu) {
                  return (
                    <div className={`${theme1Styles.navBx}`}>
                      <ul>
                        {menus.map((menu) => {
                          if (menu.children.length > 0) {
                            return (
                              <li
                                className={`${theme1Styles.subNav}`}
                                key={menu.id}
                              >
                                <a>
                                  {getTranslatedValue(
                                    menu.translations,
                                    "name",
                                    currentLocaleKey,
                                    defaultLocaleKey
                                  )}
                                </a>
                                <span
                                  onClick={() => handleSubMenu(menu.id)}
                                  className={`${
                                    isSubMenu && menuIndex == menu.id
                                      ? `${theme1Styles.open}`
                                      : ` `
                                  } ${theme1Styles.subClick}`}
                                ></span>
                                <ul
                                  className={`${
                                    isSubMenu && menuIndex == menu.id
                                      ? `${theme1Styles.open}`
                                      : ` `
                                  }`}
                                >
                                  {menu.children.map((child) => {
                                    let link = getTranslatedValue(
                                      child.translations,
                                      "link",
                                      currentLocaleKey,
                                      defaultLocaleKey
                                    );
                                    if (link) {
                                      return (
                                        <li key={child.id}>
                                          <Link to={formatLink(link)}>
                                            {getTranslatedValue(
                                              child.translations,
                                              "name",
                                              currentLocaleKey,
                                              defaultLocaleKey
                                            )}
                                          </Link>
                                        </li>
                                      );
                                    }
                                  })}
                                </ul>
                              </li>
                            );
                          } else {
                            let link = getTranslatedValue(
                              menu.translations,
                              "link",
                              currentLocaleKey,
                              defaultLocaleKey
                            );
                            if (link) {
                              return (
                                <li key={menu.id}>
                                  <Link to={formatLink(link)}>
                                    {getTranslatedValue(
                                      menu.translations,
                                      "name",
                                      currentLocaleKey,
                                      defaultLocaleKey
                                    )}
                                  </Link>
                                </li>
                              );
                            }
                          }
                        })}
                      </ul>
                    </div>
                  );
                }
              })()}
              {(() => {
                if (display_contact_form) {
                  return (
                    <div className={`${theme1Styles.contactNav} md:ml-6`}>
                      <ReactScrollLink
                        className={`${theme1Styles.btn} ${theme1Styles.btnSm} ${theme1Styles.themeBtn} cursor-pointer`}
                        to="contactSection"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        onClick={() => toggleExpansion(!isExpanded)}
                      >
                        {translateStatic(
                          "contact",
                          currentLocaleKey,
                          defaultLocaleKey
                        )}
                      </ReactScrollLink>
                    </div>
                  );
                }
              })()}
            </nav>
            <div
              className={`${isShowLang ? `${theme1Styles.open}` : ` `} ${
                theme1Styles.headDropdown
              } md:ml-6`}
            >
              <div
                className={`${theme1Styles.txt} ${theme1Styles.language}`}
                onClick={() => toggleLang(!isShowLang)}
              >
                <span className={theme1Styles.sel}>
                  <img
                    alt=""
                    src={prepareFlagURL(currentLocaleKey)}
                  />
                  {currentLocale.name}
                </span>
                <span className={theme1Styles.arrow}>
                  <img alt="" src={dnarrow} />
                </span>
              </div>
              <ul>
                {(() => {
                  return locales.map(({ name, language_key }) => {
                    return (
                      <li key={language_key}>
                        <Link
                          to={getTranslatedLink(
                            location.pathname,
                            language_key
                          )}
                        >
                          <img
                            alt=""
                            src={prepareFlagURL(language_key)}
                          />{" "}
                          {name}
                        </Link>
                      </li>
                    );
                  });
                })()}
              </ul>
            </div>
            {/* <div className={`${isShowCurrency ? `${theme1Styles.open}` : ` `} ${theme1Styles.headDropdown} ml-3 md:ml-6`}>
              <div className={`${theme1Styles.txt} ${theme1Styles.sm}`} onClick={() => toggleCurrency(!isShowCurrency)}>
                <span className={theme1Styles.sel}>AFN</span>
                <span className={theme1Styles.arrow}>
                  <img alt="" src={dnarrow} />
                </span>
              </div>
              <ul>
                <li>
                  <a href='#' onClick={e => e.preventDefault()}>AFN</a>
                </li>
                <li>
                  <a href='#' onClick={e => e.preventDefault()}>EUR</a>
                </li>
                <li>
                  <a href='#' onClick={e => e.preventDefault()}>USD</a>
                </li>
                <li>
                  <a href='#' onClick={e => e.preventDefault()}>AUD</a>
                </li>
              </ul>
            </div> */}

            <button
              className={`flex items-center block px-3 py-2 ml-3 md:ml-6 text-white border border-white rounded md:hidden`}
              onClick={() => toggleExpansion(!isExpanded)}
            >
              <svg
                className={`w-3 h-3 fill-current`}
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
            <div
              className={`${isExpanded ? `${theme1Styles.showNow}` : ` `} ${
                theme1Styles.navOverlay
              }`}
              onClick={() => toggleExpansion(!isExpanded)}
            >
              &nbsp;
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
