import PropTypes from "prop-types";
import React, { useContext } from "react";
import Header from "./header";
// import HomeHeader from "./home-header";
import Footer from "./footer";
import SiteMetaContext from "../../../contexts/SiteMetaContext";
import * as theme4Styles from "../scss/theme4.module.scss";

function Layout({ children }) {
  const siteData = useContext(SiteMetaContext);

  const {
    remarso: {
      domainByURL: {
        website: {
          theme: colorTheme = "theme-default",
          setting: display_settings,
        },
      },
    },
    location,
  } = siteData;

  const { display_footer } = display_settings;

  // let themeColor = `${theme4Styles.themeGreen}`;
  // if (colorTheme == "theme-blue") {
  //   themeColor = `${theme4Styles.themeBlue}`;
  // } else if (colorTheme == "theme-white") {
  //   themeColor = `${theme4Styles.themeWhite}`;
  // } else if (colorTheme == "theme-orange") {
  //   themeColor = `${theme4Styles.themeOrange}`;
  // } else if (colorTheme == "theme-red") {
  //   themeColor = `${theme4Styles.themeRed}`;
  // }
  let themeColor = `${theme4Styles.themeDefault}`;
  if (colorTheme == "theme-blue") {
    themeColor = `${theme4Styles.themeBlue}`;
  } else if (colorTheme == "theme-white") {
    themeColor = `${theme4Styles.themeWhite}`;
  } else if (colorTheme == "theme-red") {
    themeColor = `${theme4Styles.themeRed}`;
  } else if (colorTheme == "theme-orange") {
    themeColor = `${theme4Styles.themeOrange}`;
  } else if (colorTheme == "theme-yellow") {
    themeColor = `${theme4Styles.themeYellow}`;
  } else if (colorTheme == "theme-green") {
    themeColor = `${theme4Styles.themeGreen}`;
  } else if (colorTheme == "theme-purple") {
    themeColor = `${theme4Styles.themePurple}`;
  }

  const wrapperClass = `flex flex-col min-h-screen font-sans text-gray-900 ${themeColor}`;

  let homepage = "true";
  if (
    location.pathname === "" ||
    location.pathname === "/" ||
    location.pathname === "/en" ||
    location.pathname === "/fr"
  ) {
    homepage = "true";
  } else {
    homepage = "false";
  }

  return (
    <div className={wrapperClass}>
      {/* {location.pathname === '/en' &&
          <Header/>
      }
      {location.pathname !== '/en' &&
        <div className={`${theme4Styles.innerHeaderC}`}>
          <Header/>
        </div>
      } */}

      {homepage === "true" && <Header />}
      {homepage === "false" && (
        <div className={`${theme4Styles.innerHeaderC}`}>
          <Header />
        </div>
      )}
      <main className="">{children}</main>
      {(() => {
        if (display_footer) {
          return <Footer />;
        }
      })()}
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
