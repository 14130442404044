import banner from "../themes/theme1/images/banner.jpg";
import petroleum from "../themes/theme1/images/petroleum.png";
import loan from "../themes/theme1/images/loan.png";
import property from "../themes/theme1/images/property.png";

export const theme1Vars = [
  {
    slug: "home-background-img",
    image: banner,
  },
  {
    slug: "home-section-1-icon",
    name: "Home Section 1 Icon",
    logo: petroleum,
  },
  {
    slug: "home-section-2-icon",
    name: "Home Section 2 Icon",
    logo: loan,
  },
  {
    slug: "home-section-3-icon",
    name: "Home Section 3 Icon",
    logo: property,
  },
];

export const theme2Vars = [
  {
    slug: "home-background-img",
    image: banner,
  },
  {
    slug: "home-section-1-icon",
    name: "Home Section 1 Icon",
    logo: petroleum,
  },
  {
    slug: "home-section-2-icon",
    name: "Home Section 2 Icon",
    logo: loan,
  },
  {
    slug: "home-section-3-icon",
    name: "Home Section 3 Icon",
    logo: property,
  },
];

export const theme3Vars = [
  {
    slug: "home-background-img",
    image: banner,
  },
  {
    slug: "home-section-1-icon",
    name: "Home Section 1 Icon",
    logo: petroleum,
  },
  {
    slug: "home-section-2-icon",
    name: "Home Section 2 Icon",
    logo: loan,
  },
  {
    slug: "home-section-3-icon",
    name: "Home Section 3 Icon",
    logo: property,
  },
];

export const theme4Vars = [
  {
    slug: "home-background-img",
    image: banner,
  },
  {
    slug: "home-section-1-icon",
    name: "Home Section 1 Icon",
    logo: petroleum,
  },
  {
    slug: "home-section-2-icon",
    name: "Home Section 2 Icon",
    logo: loan,
  },
  {
    slug: "home-section-3-icon",
    name: "Home Section 3 Icon",
    logo: property,
  },
];

export const theme5Vars = [
  {
    slug: "home-background-img",
    image: banner,
  },
];

export const templateVariableValue = (
  templateConfiguration,
  templateVariables,
  slug,
  locale,
  defaultLocale = "en"
) => {
  const variable = getTemplateVariable(
    templateConfiguration,
    slug,
    locale,
    defaultLocale
  );

  if (variable) {
    const { value, templateVariable } = variable;
    if (value) {
      return value;
    } else if (templateVariable) {
      if (templateVariable.type == "file") {
        return templateVariable.logo;
      }

      return templateVariable.default_value;
    }
  } else {
    const templateVariable = templateVariables.find(
      (elem) => elem.slug == slug
    );

    if (templateVariable && templateVariable.type == "file") {
      return templateVariable.logo;
    }

    return templateVariable ? templateVariable.default_value : null;
  }

  return null;
};

export const getTemplateVariable = (
  templateConfiguration,
  slug,
  locale,
  defaultLocale = "en"
) => {
  const variables = templateConfiguration.filter(
    (conf) => conf.templateVariable.slug === slug && conf.value
  );

  if (variables.length == 0) {
    return null;
  }

  let variableOne = null;
  let variableTwo = null;

  for (var i in variables) {
    if (variables[i].locale == locale) {
      variableOne = variables[i];
      break;
    }
    if (variables[i].locale == defaultLocale) {
      variableTwo = variables[i];
    }
  }

  return variableOne || variableTwo || variables[0];
};
