import React, { useContext } from "react";
import PropTypes from "prop-types";
import IntlTelInput from "react-intl-tel-input";

import SiteMetaContext from "../../../contexts/SiteMetaContext";
import { translateStatic } from "../../../helpers/translationHelper";

import * as theme1Styles from "../scss/theme1.module.scss";

function ContactFormComponent1(props) {
  const {
    data: { errors },
  } = props;

  const siteData = useContext(SiteMetaContext);
  const {
    remarso: {
      domainByURL: {
        website: {
          setting: {
            display_message_in_form
          }
        },
      },
    },
  } = siteData;

  return (
    <>
      {/* {(props.data.ipDetails) ? JSON.stringify(props.data.ipDetails) : ''} */}
      <span className={`text-green-500 text-xs`}>
        {props.data.api_success || ""}
      </span>
      <span className={`text-orange-500 text-xs`}>
        {props.data.api_error || ""}
      </span>
      <form onSubmit={props.handleSubmit}>
        <div className={`${theme1Styles.fieldRow}`}>
          <div className={`${theme1Styles.fieldColm}`}>
            <div className={`${theme1Styles.formRow}`}>
              <input
                type="text"
                className={theme1Styles.field}
                name="first_name"
                value={props.data.first_name}
                onChange={props.handleChange}
                onBlur={props.handleChange}
                placeholder={translateStatic(
                  "first_name",
                  props.locale,
                  props.defaultLocale
                )}
              />
              {errors.first_name.length > 0 && (
                <span className={`text-red-500 text-xs`}>{errors.first_name}</span>
              )}
            </div>
          </div>
          <div className={`${theme1Styles.fieldColm}`}>
            <div className={`${theme1Styles.formRow}`}>
              <input
                type="text"
                className={theme1Styles.field}
                name="last_name"
                value={props.data.last_name}
                onChange={props.handleChange}
                onBlur={props.handleChange}
                placeholder={translateStatic(
                  "last_name",
                  props.locale,
                  props.defaultLocale
                )}
              />
              {errors.last_name.length > 0 && (
                <span className={`text-red-500 text-xs`}>{errors.last_name}</span>
              )}
            </div>
          </div>
        </div>
        <div className={`${theme1Styles.formRow}`}>
          <IntlTelInput
            fieldName="phone"
            containerClassName={`intl-tel-input`}
            inputClassName={theme1Styles.field}
            value={props.data.phone}
            defaultCountry={props.data.phone_country}
            formatOnInit={false}
            onPhoneNumberChange={(valid, number, country) =>
              props.handlePhoneChange(valid, number, country)
            }
            onPhoneNumberBlur={(valid, number, country) =>
              props.handlePhoneChange(valid, number, country)
            }
            placeholder={translateStatic(
              "phone_number",
              props.locale,
              props.defaultLocale
            )}
          />
          {errors.phone.length > 0 && (
            <span className={`text-red-500 text-xs`}>{errors.phone}</span>
          )}
        </div>
        <div className={`${theme1Styles.formRow}`}>
          <input
            type="text"
            className={theme1Styles.field}
            name="email"
            value={props.data.email}
            onChange={props.handleChange}
            onBlur={props.handleChange}
            placeholder={translateStatic(
              "email_address",
              props.locale,
              props.defaultLocale
            )}
          />
          {errors.email.length > 0 && (
            <span className={`text-red-500 text-xs`}>
              {errors.email}
            </span>
          )}
        </div>        
        {display_message_in_form && <div className={`${theme1Styles.formRow}`}>
          <textarea
            className={theme1Styles.field}
            name="description"
            value={props.data.description}
            onChange={props.handleChange}
            onBlur={props.handleChange}
            placeholder={translateStatic(
              "message",
              props.locale,
              props.defaultLocale
            )}
          ></textarea>  
        </div>}
        <div className={theme1Styles.buttonset}>
          <button
            className={`${theme1Styles.btn} ${theme1Styles.btnMd} ${theme1Styles.contactBtn} ${theme1Styles.btnBlock} ${props.data.submitted ? theme1Styles.btnDisabled : ''}`}
            disabled={props.data.submitted}
          >
            {translateStatic(
              "request_more_info",
              props.locale,
              props.defaultLocale
            )}
          </button>
        </div>
      </form>
    </>
  );
}
ContactFormComponent1.propTypes = {
  handlePhoneChange: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  data: PropTypes.object,
  locale: PropTypes.string,
  defaultLocale: PropTypes.string,
};
export default ContactFormComponent1;
