import React, { useContext } from "react";
// import { Link } from "gatsby"

import SiteMetaContext from "../../../contexts/SiteMetaContext";
import ContactFormContainer from "../../../common/ContactFormContainer";
import { templateVariableValue } from "../../../helpers/templateVariables";
import { translateStatic } from "../../../helpers/translationHelper";
import * as theme2Styles from "../scss/theme2.module.scss";

import default_logo from "../images/logo.png";
import mail from "../images/mail.png";
import phone from "../images/phone.png";
import website from "../images/www.png";

function Footer() {
  const siteData = useContext(SiteMetaContext);
  const websiteName = siteData.remarso.domainByURL.website.name
    ? siteData.remarso.domainByURL.website.name
    : siteData.site.siteMetaData.title;
  const {
    remarso: {
      domainByURL: {
        website: {
          logo,
          templateConfiguration,
          template: { templateVariables },
          company: {
            address,
            city,
            country,
            logo: company_logo,
            state,
            postal_code,
            email: company_email,
            website: company_website,
            phone: company_phone,
            socialProfiles,
          },
          contact_detail_user: user,
          setting: display_settings,
        },
      },
    },
    currentLocaleKey: locale,
    defaultLocaleKey: defaultLocale,
  } = siteData;

  const {
    display_company_logo,
    display_contact_form,
    display_contact_address,
    display_contact_phone,
    display_contact_website,
    display_contact_email,
  } = display_settings;

  const footerLogo = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "logo-footer",
    locale,
    defaultLocale
  );
  const showLogo = footerLogo || logo || company_logo || default_logo;

  const footerText = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "about-footer",
    locale,
    defaultLocale
  );

  const contact_email = user ? user.email : company_email;
  const contact_phone = user
    ? user.countryCode + user.mobile_phone
    : company_phone;
  const social_profiles = user ? user.socialProfiles : socialProfiles;

  let country_name = country ? country.country_name : null;

  return (
    <footer className={`${theme2Styles.mainFooter}`}>
      <div className={`${theme2Styles.footerTop}`}>
        <div className={theme2Styles.wrapper}>
          <h2>{translateStatic("contact_us_text", locale, defaultLocale)}</h2>
        </div>
      </div>
      <div className={theme2Styles.wrapper}>
        <div className={theme2Styles.fMainRow}>
          <div className={theme2Styles.colm}>
            <div className={theme2Styles.footerInfo}>
              {(() => {
                if (display_company_logo) {
                  return (
                    <a
                      href="#"
                      onClick={(e) => e.preventDefault()}
                      className={`${theme2Styles.footerLogo}`}
                    >
                      <img
                        alt="logo"
                        className={theme2Styles.logo}
                        src={showLogo}
                      />
                    </a>
                  );
                }
              })()}
              <p>{footerText}</p>
            </div>
          </div>
          <div className={theme2Styles.colm}>
            <div className={theme2Styles.footerLinks}>
              <h6>{translateStatic("contact", locale, defaultLocale)}</h6>
              <div className={theme2Styles.fSmRow}>
                <div className={theme2Styles.colm}>
                  <ul className={theme2Styles.socialLinks}>
                    {display_contact_email && contact_email && (
                      <li className={theme2Styles.alignTop}>
                        <img alt="mail" src={mail} /> {contact_email}
                      </li>
                    )}
                    {display_contact_phone && contact_phone && (
                      <li>
                        <img alt="phone" src={phone} /> {contact_phone}
                      </li>
                    )}
                    {display_contact_website && !user && company_website && (
                      <li>
                        <a
                          href={company_website}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img alt="website" src={website} /> {company_website}
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
                <div className={theme2Styles.colm}>
                  {social_profiles.length > 0 && (
                    <ul className={theme2Styles.socialLinks}>
                      {social_profiles.map(({ type, profile }) => {
                        return (
                          <li key={type}>
                            <a
                              href={profile}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                alt={type}
                                src={require(`../images/social/${type}.png`).default}
                              />
                              {translateStatic(type, locale, defaultLocale)}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </div>
              {display_contact_address && !user && (
                <p>
                  <strong>
                    {translateStatic("address", locale, defaultLocale)}:{" "}
                  </strong>
                  <br />
                  {address},
                  <br />
                  {city},{state}-{postal_code}
                  {country_name && <br />}
                  {country_name}
                </p>
              )}
            </div>
          </div>
          {(() => {
            if (display_contact_form) {
              return (
                <div className={theme2Styles.colm} id="contactSection">
                  <div className={`${theme2Styles.contactUs}`}>
                    <h6>
                      {translateStatic("contact_us", locale, defaultLocale)}
                      <small>
                        {translateStatic(
                          "contact_form_text",
                          locale,
                          defaultLocale
                        )}
                      </small>
                    </h6>
                    <ContactFormContainer />
                  </div>
                </div>
              );
            }
          })()}
        </div>
      </div>

      {/* <div className="">
                <div className="wrapper">
                    &copy; {websiteName}. All rights resevered.
                </div>
            </div> */}
      <div className={theme2Styles.copyright}>
        <div className={theme2Styles.wrapper}>
          <div className={`${theme2Styles.copyrightC}`}>
            <div className={theme2Styles.copyrightTxt}>
              &copy; {websiteName}. All rights resevered.
            </div>{" "}
            <div className={theme2Styles.poweredBy}>
              Powered by{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://realestatecrm.io/"
              >
                RealEstateCRM.io
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
