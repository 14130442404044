import React, { useState, useContext } from "react";
import { Link } from "gatsby";

import default_logo from "../images/logo.png";
import SiteMetaContext from "../../../contexts/SiteMetaContext";
import { templateVariableValue } from "../../../helpers/templateVariables";
import {
  getTranslatedValue,
  getTranslatedLink,
  translateStatic,
} from "../../../helpers/translationHelper";
import { formatLink, prepareFlagURL } from "../../../helpers/helper";
import ContactFormContainer from "../../../common/ContactFormContainer";
import * as theme4Styles from "../scss/theme4.module.scss";

import dnarrow from "../images/down-arrow-black.svg";
import profilePic from "../images/profile-pic.jpg";
import close from "../images/close.png";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);
  const [isShowLang, toggleLang] = useState(false);
  const [isShowModal, toggleModal] = useState(false);
  const [isSubMenu, toggleSubMenu] = useState(false);
  const [menuIndex, setMenuIndex] = useState(null);
  const siteData = useContext(SiteMetaContext);

  const handleSubMenu = (menu_id) => {
    if (menu_id == menuIndex) {
      toggleSubMenu(!isSubMenu);
    } else {
      toggleSubMenu(true);
      setMenuIndex(menu_id);
    }
  };

  const {
    remarso: {
      domainByURL: {
        website: {
          company: { logo: company_logo },
          logo,
          menus,
          setting: display_settings,
          webform_assigned_to_user,
          webform_assigned_to_group,
          templateConfiguration,
          template: { templateVariables },
        },
      },
    },
    location,
    locales,
    currentLocale,
    currentLocaleKey,
    defaultLocaleKey,
  } = siteData;

  const headerLogo = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "logo-header",
    currentLocaleKey,
    defaultLocaleKey
  );
  const showLogo = headerLogo || logo || company_logo || default_logo;

  const contactLogo = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "contact-logo",
    currentLocaleKey,
    defaultLocaleKey
  );

  const assigned_user =
    webform_assigned_to_user || webform_assigned_to_group?.leader;

  const {
    display_company_logo,
    display_menu,
    display_contact_form,
  } = display_settings;

  return (
    <header className={`${theme4Styles.mainHeader}`}>
      <div className={theme4Styles.wrapper}>
        <div className={theme4Styles.headerArea}>
          {(() => {
            if (display_company_logo) {
              return (
                <Link
                  to={getTranslatedLink("/", currentLocaleKey)}
                  className={theme4Styles.logo}
                >
                  <h1>
                    <img alt="logo" src={showLogo} />
                  </h1>
                </Link>
              );
            }
          })()}

          <div className={theme4Styles.headerRight}>
            <nav
              className={`${theme4Styles.navbar} ${
                isExpanded ? `${theme4Styles.showNow}` : ` `
              } `}
            >
              {/* <nav className={`${isExpanded ? `show-now` : ` `} md:block md:flex md:items-center w-full md:w-auto nav-c`}> */}
              <div className={theme4Styles.navBx}>
                <ul>
                  {(() => {
                    if (display_menu) {
                      return menus.map((menu) => {
                        if (menu.children.length > 0) {
                          return (
                            <li
                              className={`${theme4Styles.subNav}`}
                              key={menu.id}
                            >
                              <a>
                                {getTranslatedValue(
                                  menu.translations,
                                  "name",
                                  currentLocaleKey,
                                  defaultLocaleKey
                                )}
                              </a>
                              <span
                                onClick={() => handleSubMenu(menu.id)}
                                className={`${
                                  isSubMenu && menuIndex == menu.id
                                    ? `${theme4Styles.open}`
                                    : ` `
                                } ${theme4Styles.subClick}`}
                              ></span>
                              <ul
                                className={`${
                                  isSubMenu && menuIndex == menu.id
                                    ? `${theme4Styles.open}`
                                    : ` `
                                }`}
                              >
                                {menu.children.map((child) => {
                                  let link = getTranslatedValue(
                                    child.translations,
                                    "link",
                                    currentLocaleKey,
                                    defaultLocaleKey
                                  );
                                  if (link) {
                                    return (
                                      <li key={child.id}>
                                        <Link to={formatLink(link)}>
                                          {getTranslatedValue(
                                            child.translations,
                                            "name",
                                            currentLocaleKey,
                                            defaultLocaleKey
                                          )}
                                        </Link>
                                      </li>
                                    );
                                  }
                                })}
                              </ul>
                            </li>
                          );
                        } else {
                          let link = getTranslatedValue(
                            menu.translations,
                            "link",
                            currentLocaleKey,
                            defaultLocaleKey
                          );
                          if (link) {
                            return (
                              <li key={menu.id}>
                                <Link to={formatLink(link)}>
                                  {getTranslatedValue(
                                    menu.translations,
                                    "name",
                                    currentLocaleKey,
                                    defaultLocaleKey
                                  )}
                                </Link>
                              </li>
                            );
                          }
                        }
                      });
                    }
                  })()}
                  {(() => {
                    if (display_contact_form) {
                      return (
                        <li>
                          <a
                            onClick={() => (
                              toggleModal(!isShowModal),
                              toggleExpansion(!isExpanded)
                            )}
                          >
                            Contact
                          </a>
                        </li>
                      );
                    }
                  })()}
                </ul>
                <div
                  className={`${theme4Styles.contactModal} ${
                    isShowModal ? `${theme4Styles.show}` : ` `
                  }`}
                >
                  <div className={`${theme4Styles.contactUs}`}>
                    <span
                      onClick={() => toggleModal(!isShowModal)}
                      className={theme4Styles.close}
                    >
                      <img alt="close" src={close} />
                    </span>
                    <h6>
                      {translateStatic(
                        "contact_us",
                        currentLocaleKey,
                        defaultLocaleKey
                      )}
                      <small>
                        {translateStatic(
                          "contact_form_text",
                          currentLocaleKey,
                          defaultLocaleKey
                        )}
                      </small>
                    </h6>
                    <ContactFormContainer />
                    {(() => {
                      if (assigned_user) {
                        let phone_number = "";
                        phone_number += assigned_user.countryCode
                          ? assigned_user.countryCode + "-"
                          : "";
                        phone_number += assigned_user.mobile_phone
                          ? assigned_user.mobile_phone
                          : "";

                        return (
                          <div className={`${theme4Styles.contactAgent}`}>
                            <div className={theme4Styles.photo}>
                              <img
                                alt="phone"
                                src={
                                  contactLogo ||
                                  assigned_user.profile_image ||
                                  profilePic
                                }
                              />
                            </div>
                            <div className={theme4Styles.details}>
                              <span className={theme4Styles.nm}>
                                {assigned_user.name}
                              </span>
                              <span className={theme4Styles.txt}>
                                <strong>
                                  {translateStatic(
                                    "email",
                                    currentLocaleKey,
                                    defaultLocaleKey
                                  )}
                                  :{" "}
                                </strong>
                                {assigned_user.email}
                              </span>
                              <span className={theme4Styles.txt}>
                                <strong>
                                  {translateStatic(
                                    "phone",
                                    currentLocaleKey,
                                    defaultLocaleKey
                                  )}
                                  :{" "}
                                </strong>
                                {phone_number}
                              </span>
                            </div>
                          </div>
                        );
                      }
                    })()}
                  </div>
                </div>
              </div>
              {/*<div className="contact-nav md:ml-6">
                  <a href='#' onClick={e => e.preventDefault()} className="btn btn-sm btn-warning">Contact</a>
                  <ReactScrollLink
                    className="btn btn-sm btn-warning cursor-pointer"
                    // activeClass="active"
                    to="contactSection"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    onClick={() => toggleExpansion(!isExpanded)}
                  >
                    Contact
                  </ReactScrollLink>
                </div> */}
            </nav>
            <div
              className={`${theme4Styles.headDropdown} ${
                isShowLang ? `${theme4Styles.open}` : ` `
              }`}
            >
              <div
                className={theme4Styles.txt}
                onClick={() => toggleLang(!isShowLang)}
              >
                <span className={theme4Styles.sel}>
                  <img
                    alt=""
                    src={prepareFlagURL(currentLocaleKey)}
                  />
                  {currentLocale.name}
                </span>
                <span className={theme4Styles.arrow}>
                  <img alt="" src={dnarrow} />
                </span>
              </div>
              <ul>
                {locales.map(({ name, language_key }) => {
                  return (
                    <li key={language_key}>
                      <Link
                        to={getTranslatedLink(location.pathname, language_key)}
                      >
                        <img
                          alt=""
                          src={prepareFlagURL(language_key)}
                        />{" "}
                        {name}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>

            <button
              className={`${theme4Styles.mobileNavBar} flex items-center block px-3 py-2 ml-3 md:ml-6 text-white border border-white rounded md:hidden`}
              onClick={() => toggleExpansion(!isExpanded)}
            >
              <svg
                className={`w-3 h-3 fill-current`}
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
            <div
              className={`${isExpanded ? `${theme4Styles.showNow}` : ` `} ${
                theme4Styles.navOverlay
              }`}
              onClick={() => toggleExpansion(!isExpanded)}
            >
              &nbsp;
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
