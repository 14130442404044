import React, { useState, useContext } from "react";
import { Link } from "gatsby";

import SiteMetaContext from "../../../contexts/SiteMetaContext";
import { templateVariableValue } from "../../../helpers/templateVariables";
import {
  getTranslatedValue,
  getTranslatedLink,
} from "../../../helpers/translationHelper";
import { formatLink, prepareFlagURL } from "../../../helpers/helper";
import * as theme2Styles from "../scss/theme2.module.scss";

import default_logo from "../images/logo.png";
import dnarrow from "../images/down-arrow-black.svg";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);
  const [isShowLang, toggleLang] = useState(false);
  const [isSubMenu, toggleSubMenu] = useState(false);
  const [menuIndex, setMenuIndex] = useState(null);
  const siteData = useContext(SiteMetaContext);

  const handleSubMenu = (menu_id) => {
    if (menu_id == menuIndex) {
      toggleSubMenu(!isSubMenu);
    } else {
      toggleSubMenu(true);
      setMenuIndex(menu_id);
    }
  };

  const {
    remarso: {
      domainByURL: {
        website: {
          company: { logo: company_logo },
          logo,
          menus,
          setting: display_settings,
          templateConfiguration,
          template: { templateVariables },
        },
      },
    },
    location,
    locales,
    currentLocale,
    currentLocaleKey,
    defaultLocaleKey,
  } = siteData;

  const headerLogo = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "logo-header",
    currentLocaleKey,
    defaultLocaleKey
  );
  const showLogo = headerLogo || logo || company_logo || default_logo;

  const { display_company_logo, display_menu } = display_settings;

  return (
    <header className={`${theme2Styles.mainHeader}`}>
      <div className={theme2Styles.wrapper}>
        <div className={`flex justify-between`}>
          {(() => {
            if (display_company_logo) {
              return (
                <Link to={getTranslatedLink("/", currentLocaleKey)}>
                  <h1 className={`flex items-center text-white no-underline`}>
                    <img
                      alt="logo"
                      className={theme2Styles.logo}
                      src={showLogo}
                    />
                  </h1>
                </Link>
              );
            }
          })()}
          <div className={`${theme2Styles.headerRight}`}>
            <nav
              className={`${theme2Styles.navC} ${
                isExpanded ? `${theme2Styles.showNow}` : ` `
              } md:block md:flex md:items-center w-full md:w-auto`}
            >
              <div className={`${theme2Styles.navBx}`}>
                <ul>
                  {(() => {
                    if (display_menu) {
                      return menus.map((menu) => {
                        if (menu.children.length > 0) {
                          return (
                            <li
                              className={`${theme2Styles.subNav}`}
                              key={menu.id}
                            >
                              <a>
                                {getTranslatedValue(
                                  menu.translations,
                                  "name",
                                  currentLocaleKey,
                                  defaultLocaleKey
                                )}
                              </a>
                              <span
                                onClick={() => handleSubMenu(menu.id)}
                                className={`${
                                  isSubMenu && menuIndex == menu.id
                                    ? `${theme2Styles.open}`
                                    : ` `
                                } ${theme2Styles.subClick}`}
                              ></span>
                              <ul
                                className={`${
                                  isSubMenu && menuIndex == menu.id
                                    ? `${theme2Styles.open}`
                                    : ` `
                                }`}
                              >
                                {menu.children.map((child) => {
                                  let link = getTranslatedValue(
                                    child.translations,
                                    "link",
                                    currentLocaleKey,
                                    defaultLocaleKey
                                  );
                                  if (link) {
                                    return (
                                      <li key={child.id}>
                                        <Link to={formatLink(link)}>
                                          {getTranslatedValue(
                                            child.translations,
                                            "name",
                                            currentLocaleKey,
                                            defaultLocaleKey
                                          )}
                                        </Link>
                                      </li>
                                    );
                                  }
                                })}
                              </ul>
                            </li>
                          );
                        } else {
                          let link = getTranslatedValue(
                            menu.translations,
                            "link",
                            currentLocaleKey,
                            defaultLocaleKey
                          );
                          if (link) {
                            return (
                              <li key={menu.id}>
                                <Link to={formatLink(link)}>
                                  {getTranslatedValue(
                                    menu.translations,
                                    "name",
                                    currentLocaleKey,
                                    defaultLocaleKey
                                  )}
                                </Link>
                              </li>
                            );
                          }
                        }
                      });
                    }
                  })()}
                </ul>
              </div>
            </nav>
            <div
              className={`${isShowLang ? `${theme2Styles.open}` : ` `} ${
                theme2Styles.headDropdown
              } md:ml-6`}
            >
              <div
                className={`${theme2Styles.txt} ${theme2Styles.language}`}
                onClick={() => toggleLang(!isShowLang)}
              >
                <span className={theme2Styles.sel}>
                  <img
                    alt=""
                    src={prepareFlagURL(currentLocaleKey)}
                  />
                  {currentLocale.name}
                </span>
                <span className={theme2Styles.arrow}>
                  <img alt="" src={dnarrow} />
                </span>
              </div>
              <ul>
                {locales.map(({ name, language_key }) => {
                  return (
                    <li key={language_key}>
                      <Link
                        to={getTranslatedLink(location.pathname, language_key)}
                      >
                        <img
                          alt=""
                          src={prepareFlagURL(language_key)}
                        />{" "}
                        {name}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>

            <button
              className={`${theme2Styles.navBtn} flex items-center block px-3 py-2 ml-3 md:ml-6 text-white border border-white rounded md:hidden`}
              onClick={() => toggleExpansion(!isExpanded)}
            >
              <svg
                className={`w-3 h-3 fill-current`}
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
            <div
              className={`${isExpanded ? `${theme2Styles.showNow}` : ` `} ${
                theme2Styles.navOverlay
              }`}
              onClick={() => toggleExpansion(!isExpanded)}
            >
              &nbsp;
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
