const trackip = async () => {
  // const result = await fetch('//extreme-ip-lookup.com/json/');
  const result = await fetch("//extreme-ip-lookup.com/json/").catch((err) => {
    console.error(err);
  });
  // response will be undefined if the promise is rejected
  const response =
    result === undefined
      ? ""
      : await result.json().catch((err) => {
          console.error(err);
        });
  // console.log('res', response);
  return response;
};
export default trackip;
